/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-panel {
  flex: 1;
}

.neuroglancer-show-panel-borders .neuroglancer-panel {
  border-style: solid;
  border-color: transparent;
  /* border-color: black; */
  border-width: 2px;
}

.neuroglancer-panel:focus-within {
  border-color: white;
}

.neuroglancer-layer-group-viewer {
  outline: 0px;
}

.neuroglancer-layer-group-viewer-context-menu {
  flex-direction: column;
  align-items: stretch;
}

.neuroglancer-layer-group-viewer-context-menu label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.neuroglancer-layer-group-viewer-context-menu select::before {
  flex: 1;
  content: " ";
}

.neuroglancer-layer-group-viewer-context-menu select {
  margin-left: 5px;
}
